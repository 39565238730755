module.exports = [{
      plugin: require('/opt/buildhome/repo/node_modules/gatsby-plugin-drift/gatsby-browser.js'),
      options: {"plugins":[],"appId":"cwxsge4c5bes"},
    },{
      plugin: require('/opt/buildhome/repo/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-151900135-1"},
    },{
      plugin: require('/opt/buildhome/repo/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
